<template>
    <div>
       <v-row>
          <v-col cols="12">
             <v-row>
                <v-col cols="12" class="d-flex justify-space-between">
                  <div class="page-title">{{ $route.meta.title }}</div>
                  <v-btn color="primary white--text" class="px-6 font-weight-bold" elevation="1" small :to="{ name: 'settingCreate' }" :disabled="isFetching">新增</v-btn>
                </v-col>
             </v-row>
          </v-col>
          <v-col cols="12">
             <TableView :data="data" :headers="headers" detailPage="settingDetail" />
          </v-col>
       </v-row>
    </div>
 </template>
 
 <script>
 import dummyData from "@/components/dummy-data"
 import TableView from "@/components/TableView"
 import { mapActions, mapState } from "vuex";
 
 export default {
    name: 'Setting',
    components: {
       TableView,
    },
    data() {
       return {
          responseData: [],
          headers: [
             { text: "會員等級", value: "name" },
             { text: "購買金額達到", value: "total_pay_amount" },
          ],
       }
    },
    computed: {
       ...mapState({
          isFetching: (state) => state.request.isFetching,
          isSuccess: (state) => state.request.isSuccess,
          isFail: (state) => state.request.isFail,
       }),
 
       data() {
          return this.responseData;
       },
    },
    methods: {
       ...mapActions(["sendRequest"], "request"),
       async initData() {
          const resp = await this.sendRequest({ url: '', method: 'GET', params: {} });
          if(resp.data) {
             this.responseData = dummyData.setting_list ? dummyData.setting_list.map(item => {
                return {
                   ...item,
                }
             }) : [];
          }
       },
    },
    async mounted() {
       await this.initData();
    }
 }
 </script>
 
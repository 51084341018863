var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"rounded":"lg"}},[_c('v-card-text',{staticClass:"px-0"},[_c('v-data-table',{staticClass:"table-rounded",attrs:{"headers":_vm.tableHeader,"items":_vm.tableData,"item-key":"id","disable-sort":"","no-data-text":"沒有資料","loading-text":"載入資料中...","loading":_vm.isFetching},scopedSlots:_vm._u([{key:"item.img_url",fn:function(ref){
var item = ref.item;
return [_c('v-img',{staticClass:"ma-1",attrs:{"src":item.img_url,"contain":"","width":"45","height":"45"}})]}},{key:"item.stock",fn:function(ref){
var item = ref.item;
return [(item.stock == 0)?_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.stock))]):_c('span',[_vm._v(_vm._s(item.stock))])]}},{key:"item.member_status_text",fn:function(ref){
var item = ref.item;
return [(item.member_status_text == '啟用中')?_c('span',{staticClass:"green--text"},[_vm._v(_vm._s(item.member_status_text))]):(item.member_status_text == '停用')?_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.member_status_text))]):_c('span',[_vm._v(_vm._s(item.member_status_text))])]}},{key:"item.member_approve_status_text",fn:function(ref){
var item = ref.item;
return [(item.member_approve_status_text == '未審批')?_c('span',{staticClass:"orange--text"},[_vm._v(_vm._s(item.member_approve_status_text))]):_c('span',[_vm._v(_vm._s(item.member_approve_status_text))])]}},(!_vm.noViewBtn)?{key:"item.id",fn:function(ref){
var item = ref.item;
return [(_vm.detailPage == null)?_c('v-btn',{staticClass:"px-8",attrs:{"icon":"","color":"primary","small":"","plain":""},on:{"click":function () { return _vm.$emit('showDialog', item.id); }}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiEye))]),_vm._v(" 查看 ")],1):_c('v-btn',{staticClass:"px-8",attrs:{"icon":"","color":"primary","small":"","to":_vm.toPage(item.id),"plain":""}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiEye))]),_vm._v(" 查看 ")],1)]}}:null],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
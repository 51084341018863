<template>
   <v-card rounded="lg">
      <v-card-text class="px-0">
         <v-data-table
         :headers="tableHeader"
         :items="tableData"
         item-key="id"
         class="table-rounded"
         disable-sort
         no-data-text="沒有資料"
         loading-text="載入資料中..."
         :loading="isFetching"
      >
         <template v-slot:[`item.img_url`]="{ item }">
            <v-img :src="item.img_url" class="ma-1" contain width="45" height="45" />
         </template>

         <template v-slot:[`item.stock`]="{ item }">
            <span v-if="item.stock == 0" class="red--text">{{ item.stock }}</span>
            <span v-else>{{ item.stock }}</span>
         </template>

         <template v-slot:[`item.member_status_text`]="{ item }">
            <span v-if="item.member_status_text == '啟用中'" class="green--text">{{ item.member_status_text }}</span>
            <span v-else-if="item.member_status_text == '停用'" class="red--text">{{ item.member_status_text }}</span>
            <span v-else>{{ item.member_status_text }}</span>
         </template>

         <template v-slot:[`item.member_approve_status_text`]="{ item }">
            <span v-if="item.member_approve_status_text == '未審批'" class="orange--text">{{ item.member_approve_status_text }}</span>
            <span v-else>{{ item.member_approve_status_text }}</span>
         </template>
         
         <template #[`item.id`]="{ item }" v-if="!noViewBtn">
            <v-btn icon color="primary" small class="px-8" @click="() => $emit('showDialog', item.id)" plain v-if="detailPage == null">
               <v-icon>{{icons.mdiEye}}</v-icon>
               查看
            </v-btn>
            <v-btn icon color="primary" small class="px-8" :to="toPage(item.id)" plain v-else>
               <v-icon>{{icons.mdiEye}}</v-icon>
               查看
            </v-btn>
         </template>
         
      </v-data-table>
      </v-card-text>
   </v-card>
</template>

<script>
import { mapState } from "vuex";
import { mdiEye } from '@mdi/js'

export default {
   name: "TableView",
   props: ["headers", "data", "detailPage", "noViewBtn"],
   setup() {
      return {
         icons: {
            mdiEye,
         }
      }
   },
   computed: {
      ...mapState({
         isFetching: (state) => state.request.isFetching,
         isSuccess: (state) => state.request.isSuccess,
         isFail: (state) => state.request.isFail,
      }),
      tableHeader() {
         let header = this.headers;
         if(!this.noViewBtn) {
            header.push({ text: '', value: 'id', align: 'center', sortable: false });
         }
         return header;
      },
      tableData() {
         if(this.data) {
            let result = this.data;
            result.forEach(row => {
               Object.keys(row).forEach(key => {
                  if(row[key] === '' || row[key] === null || row[key] === undefined) {
                     row[key] = '-'
                  }
               })
            })
            return result;
         }
      },
   },
   methods: {
      toPage(id) {
         if(this.detailPage) {
            return {
               name: this.detailPage,
               params: {
                  id: id
               }
            }
         }
      }
   }
};
</script>
